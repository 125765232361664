<template>
	<v-app class="full-viewport-contained" :dark="darkMode" @dragover="preventDefaults" @drop="preventDefaults">
		<router-view />
		<SupportChatButton v-if="canDisplaySupportChatButton" />
		<SupportChat v-if="!conditionalDisplay.isMobileApp && !loadingWhiteLabelData" />
		<SnackBar />
		<LoadingDialog :loading="loadingCounter > 0" color="primary"></LoadingDialog>
		<v-snackbar v-model="askPermissionForNotifications" absolute top multi-line :timeout="0">
			<v-flex v-t="'enableNotifications'" justify-center align-center />
			<v-btn flat color="primary" @click="askPermission">{{ $t('authorize') }}</v-btn>
		</v-snackbar>
		<ConnectionChecker />
		<WDialogSingleton />
		<span v-if="!conditionalDisplay.embedded" class="caption font-italic text-xs-right app-version">{{ `v${appVersion}` }}</span>
	</v-app>
</template>
<script>
import * as Sentry from "@sentry/browser";
import PageTitleMixin from "@/mixins/PageTitle";
import ZoomCatcher from "@/mixins/ZoomCatcher";
import { mapState } from "vuex";
import { AppModuleGuardFactory } from "@/mixins/ModulesGuards/AppModuleGuard";
import WhiteLabelService from "@/services/WhiteLabel/WhiteLabelService";

export default {
	name: 'App',
	components: {
		ConnectionChecker: () => ({
			component: import('@/components/Navigation/ConnectionChecker')
		}),
		LoadingDialog: () => ({
			component: import('@/components/Navigation/LoadingDialog')
		}),
		SnackBar: () => ({
			component: import('@/components/Navigation/SnackBar')
		}),
		SupportChat: () => ({
			component: import('@/components/Support/SupportChat')
		}),
		SupportChatButton: () => ({
			component: import('@/components/Support/SupportChatButton')
		}),
		WDialogSingleton: () => ({
			component: import('@/components/Commons/WDialogSingleton')
		}),
	},
	mixins: [
		AppModuleGuardFactory({ injectConditionalDisplay: false }),
		PageTitleMixin,
		ZoomCatcher
	],
	reactiveProvide: {
		name: 'conditionalDisplay',
		include: ['embedded', 'isMobileApp', 'isGenericMobileApp']
	},
	data: function () {
		return {
			askPermissionForNotifications: false,
			askToRefreshNotifications: false,
			automatedRefreshTimeout: 20,
			loadingCounter: 0,
			loadingWhiteLabelData: true,
			notificationsSupported: false,
			registration: null,
			refreshing: false,
			updateExists: false,
			embedded: false,
			isMobileApp: false,
			isGenericMobileApp: false,
		}
	},
	computed: {
		...mapState({
			appVersion: state => state.appVersion,
			isNewVersionAvailable: state => state.isNewVersionAvailable,
			accountingFirm: state => state.accountingFirm.selected,
			company: state => state.company.selected,
			darkMode: state => state.user.darkMode,
			user: state => state.user,
			logo: state => state.whitelabel.selected.logo,
			isAccountant: state => state.auth.isAccountant,
			primaryColor: state => state.whitelabel.primary_color,
			secondaryColor: state => state.whitelabel.secondary_color
		}),
		canDisplaySupportChatButton: function() {
			return !this.conditionalDisplay.isMobileApp
				&& !this.loadingWhiteLabelData
				&& this.$route.name === 'signin'
		},
		pauseKeyboardListening: {
			get: function () {
				return this.$store.state.pauseKeyboardListening
			},
			set: function (value) {
				this.$store.dispatch('setPauseKeyboardListening', value)
			}
		},
		theme: function () {
			return {
				primary: this.primaryColor,
				secondary: this.secondaryColor
			}
		},
		titleTemplate: function () {
			let result
			if (this.accountingFirm?.name) {
				result = `%s | ${this.accountingFirm.name}`
			} else {
				result = `%s | ${window.location.origin}`
			}
			return result
		}
	},
	watch: {
		accountingFirm: {
			deep: true,
			handler: function () {
				this.updateThirdParties()
			}
		},
		company: {
			deep: true,
			handler: function () {
				this.updateThirdParties()
			}
		},
		user: {
			deep: true,
			handler: function () {
				this.updateSentry()
				this.updateThirdParties()
			}
		},
		'$route.query.embedded': {
			deep: true,
			handler: function () {
				this.isGenericMobileApp = (this.$route.query.hasOwnProperty('embedded') && this.$route.query.embedded === "genericmobileapp")
				this.isMobileApp = (this.$route.query.hasOwnProperty('embedded') && this.$route.query.embedded === "mobileapp") || this.isGenericMobileApp
				this.embedded = (this.$route.query.hasOwnProperty('embedded') && (this.$route.query.embedded === "true")) || this.isMobileApp
			}
		},
		'$route.name': {
			handler: function (newRouteName, oldRouteName) {
				if (!oldRouteName) {
					return;
				}
				if (newRouteName === 'signin' && oldRouteName !== 'signin') {
					this.initializeApp();
				}
			}
		}
	},
	created: async function () {
		this.initializeApp()
	},
	mounted: function () {
		this.enhanceStyles()
		this.updateThirdParties()
		if ('serviceWorker' in navigator) {
			document.addEventListener('newServiceWorkerWaiting', this.prepareCache, { once: true })
			if ('Notification' in window) {
				this.notificationsSupported = true
				/*
				// Keep this to push notifications when we'll be ready to do so
				if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
					this.askPermissionForNotifications = true
				}
				*/
			}
		}
		window.addEventListener('beforeunload', async () => {
			if (window.swNeedUpdate) {
				 await this.doRefresh()
			}
		})
	},
	methods: {
		initializeApp: async function () {
			this.appService.initDarkMode()
			this.loadingWhiteLabelData = true
			try {
				await WhiteLabelService.loadWhiteLabelData()
			} finally {
				this.loadingWhiteLabelData = false
			}
		},
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.ACCOUNTING_FIRM_SELECTED, action: this.onAccountingFirmSelected },
				{ event: this.appEvents.UPDATE_LOADING_STATUS, action: this.updateLoadingStatus },
				{ event: this.appEvents.ROUTER_GO_TO_PATH, action: this.goTo },
				{ event: this.appEvents.ROUTER_GO_IN_NEW_TAB, action: this.goToNewTab },
				{ event: this.appEvents.UPDATE_THIRD_PARTIES, action: this.updateThirdParties },
				{ event: this.appEvents.WHITE_LABEL_UPDATED, action: this.updateWhiteLabel },
				{ event: this.appEvents.PAUSE_LISTENING_TO_KEYBOARD, action: this.doPauseKeyboardListening },
				{ event: this.appEvents.RESUME_LISTENING_TO_KEYBOARD, action: this.doResumeKeyboardListening }
			]
		},
		doPauseKeyboardListening: function () {
			this.pauseKeyboardListening = true
		},
		doResumeKeyboardListening: function () {
			this.pauseKeyboardListening = false
		},
		preventDefaults: function (event) {
			event.preventDefault()
			event.stopPropagation()
		},
		doRefresh: async function () {
			await this.getWaitingWorker()
			if (!this.registration) {
				return
			}
			this.registration.waiting.postMessage({type: 'SKIP_WAITING_WHEN_SOLO'})
		},

		prepareCache: async function() {
			await this.getWaitingWorker()
			if (!this.registration) {
				return
			}
			this.registration.waiting.postMessage({ type: 'PREPARE_CACHES_FOR_UPDATE' })
		},

		getWaitingWorker: async function() {
			let registrations = await navigator.serviceWorker.getRegistrations();
			this.registration = registrations.find(reg => reg.waiting)
		},
		askPermission: function () {
			if (this.askPermissionForNotifications) {
				Notification.requestPermission(result => {
					if (result === 'granted') {
						this.showNotification()
					}
				})
			}
		},
		showNotification: function () {
			if ('serviceWorker' in navigator && this.notificationsSupported) {
				navigator.serviceWorker.ready.then(swreg =>
					// Notification example
					swreg.showNotification('Notifications granted', {
						body: 'Here is a first notification',
						icon: this.logo,
						image: this.logo,
						vibrate: [300, 200, 300],
						badge: this.logo
						/* ,
						actions: [
							{ action: 'confirm', title: 'OK', icon: '/img/icons/android-chrome-192x192.png' },
							{ action: 'cancel', title: 'KO', icon: '/img/icons/android-chrome-192x192.png' }
						]
						*/
          })
        );
      }
    },
    updateLoadingStatus: function (isLoading) {
      if (typeof isLoading == "boolean") {
        if (isLoading) {
          this.loadingCounter++;
        } else {
          this.loadingCounter--;
        }
      } else if (typeof isLoading == "number") {
        this.loadingCounter = isLoading;
      }
    },
    goToNewTab: function (asked) {
      this.appService.goToNewTab(asked);
    },
    goTo: function (asked) {
      this.appService.goTo(asked);
    },
    updateThirdParties: function () {
      if (this.$envVar.VUE_APP_MATOMO_ENABLED) {
        this.updateMatomo();
      }
    },
    updateSentry: function () {
      if (this.user.id) {
        Sentry.configureScope((scope) => {
          scope.setUser((({ id, email }) => ({ id, email }))(this.user));
        });
      }
    },
    updateMatomo: function () {
      if (this.$matomo && this.user.id) {
        this.$matomo.setUserId(this.user.id);
        this.$matomo.setCustomDimension(1, this.user.fullname);
        this.$matomo.setCustomDimension(
          2,
          this.isAccountant ? "Utilisateur Cabinet" : "Utilisateur Entreprise"
        );
        this.$matomo.setCustomDimension(3, this.user.email);
        this.$matomo.setCustomDimension(
          4,
          this.accountingFirm ? this.accountingFirm.name : ""
        );
        this.$matomo.setCustomDimension(5, this.company ? this.company.company : "");
      }
    },
    enhanceStyles: function () {
      document.documentElement.style.setProperty(
        "--v-primary-transparent",
        /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
          .exec(this.$vuetify.theme.primary)
          .map((element) => parseInt(element, 16))
          .filter((element) => !isNaN(element))
          .join()
      );
      document.documentElement.style.setProperty(
        "--v-secondary-transparent",
        /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
          .exec(this.$vuetify.theme.secondary)
          .map((element) => parseInt(element, 16))
          .filter((element) => !isNaN(element))
          .join()
      );
    },
    onAccountingFirmSelected: function (accountingFirm) {
      WhiteLabelService.onAccountingFirmSelected(accountingFirm);
    },
    updateWhiteLabel: function (whiteLabel) {
      WhiteLabelService.onWhiteLabelUpdated(whiteLabel);
    },
  },
  metaInfo: function () {
    return {
      title: this.pageTitle || window.location.origin,
      titleTemplate: this.titleTemplate,
    };
  },
};
</script>
<style>
.app-version {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 1000;
}
</style>
