import { router as ROUTER } from '@/router'

import accountingFirmSettings from '@/router/routes/auth/accountingFirmSettings'
import clientSettings from '@/router/routes/auth/clientSettings'
import customer from '@/router/routes/auth/customer'
import customers from '@/router/routes/auth/customers'
import documents from '@/router/routes/auth/documents'
import eurex from '@/router/routes/auth/eurex'
import gest4u from '@/router/routes/auth/gest4u'
import home from '@/router/routes/auth/home'
import profile from '@/router/routes/auth/profile'
import quickfolders from '@/router/routes/auth/quickfolders'
import serviceRequests from '@/router/routes/auth/serviceRequests'
import sso from '@/router/routes/auth/sso'
import superAdmin from '@/router/routes/auth/superAdmin'
import tasksManager from '@/router/routes/auth/tasksManager'
import videoconference from '@/router/routes/auth/videoconference'
import viewer from '@/router/routes/auth/viewer'
import holding from '@/router/routes/auth/holding'

const AuthenticatedPages = () => import('@/views/AuthenticatedPages')

export default [
	{
		component: AuthenticatedPages,
		meta: {
			authentication_required: true,
			historize: false
		},
		name: 'auth',
		path: '/',
		props: route => {
			const context = ROUTER.decryptContext(route.params.context)
			return {
				context: {
					accountingFirmId: context.accounting_firm_id,
					vendorId: context.vendor_id
				}
			}
		},
		redirect: { name: 'home' },
		children: [
			...accountingFirmSettings,
			...clientSettings,
			...customer,
			...customers,
			...documents,
			...eurex,
			...gest4u,
			...home,
			...profile,
			...quickfolders,
			...serviceRequests,
			...sso,
			...superAdmin,
			...tasksManager,
			...videoconference,
			...viewer,
			...holding

		]
	}
]
