import EventEmitter from 'events'
import Resumable from 'resumablejs'
import { v4 as uuid } from 'uuid'
import { Config } from '@/services/ConfigService'
import { getFileExtension } from '@/helpers/files'
import Interceptor from '@/plugins/axios/DefaultInterceptor'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

const MAX_CONCURRENT_UPLOADS = 4
const MAX_RETRIES = 1

class Job {
	constructor(url, file, uploadObject, params = {}) {
		this.url = url
		this.file = file
		this.uploadObject = uploadObject
		this.parameters = { ...params }
		this.parameters.name = this.filename
		this.tries = 0
		this.resumable = new Resumable({
			chunkRetryInterval: Config.VUE_APP_UPLOAD_RETRY_INTERVAL,
			chunkSize: Config.VUE_APP_UPLOAD_CHUNK_SIZE,
			headers: {
				Accept: 'application/json',
				Authorization: localStorage.getItem('token')
			},
			permanentErrors: [400, 401, 403, 404, 415, 422, 500, 501],
			query: { ...this.parameters },
			simultaneousUploads: Config.VUE_APP_UPLOAD_MAX_CONCURRENT_CHUNKS,
			target: this.url,
			testChunks: false
		})
	}

	get filename () {
		if (this.file.name && this.file.name.length >= 191) {
			const splittedName = this.file.name.split('.')
			const extension = splittedName.pop()
			let name = splittedName.join('.').substring(0, 190 - (extension.length + 1))
			return name + '.' + extension
		}
		return this.file.name
	}

	get uploadId () {
		return this.uploadObject.id
	}

	setupFileOriginalName () {
		Object.defineProperty(this.file, 'original_name', {
			value: this.file.name,
			writable: true,
			enumerable: true
		})
	}
	setupFileName () {
		Object.defineProperty(this.file, 'name', {
			value: `${Date.now().toString()}-${Math.floor(Math.random() * 100).toString()}.${getFileExtension(this.file?.name)}`,
			writable: true,
			enumerable: true
		})
	}
	setupResumable (resolve) {
		this.resumable.on('fileAdded', () => {
			this.resumable.upload()
		})
		
		this.resumable.on('progress', () => {
			const progress = this.resumable.progress()
			this.uploadObject.progression = Math.round(progress * 100)
			AppEventBus.emit(AppEvents.UPDATE_UPLOAD_PROGRESS, this.uploadObject)
		})
		
		this.resumable.on('error', (error, failedFile) => {
			if (this.tries < MAX_RETRIES) {
				this.tries++
				failedFile.retry()
			} else {
				AppEventBus.emit(AppEvents.UPLOAD_FAILED, this.uploadObject.id)
				AppEventBus.emit(AppEvents.REMOVE_UPLOAD_PROGRESS, this.uploadObject.id)

				let jsonError = {}
				try {
					jsonError = JSON.parse(error)
				} catch (e) {
					jsonError = { message: "Unknown upload error" }
				}
				
				Interceptor.onResponseError({
					error: jsonError?.message || jsonError?.errors?.name[0],
					config: {
						show_error: true
					},
					status: "resumable",
				}).catch(() => {
					// Catch is necessary but do nothing, error already handled
				})
			}
		})
		
		this.resumable.on('fileSuccess', successedFile => {
			let data = successedFile?.chunks?.map(chunk => JSON.parse(chunk.message()))
				.find(chunkData => chunkData?.data?.id && !chunkData?.data?.done) || {}
		
			AppEventBus.emit(AppEvents.COMPLETE_UPLOAD_PROGRESS, this.uploadObject.id)
			resolve(data, successedFile)
		})
	}

	cancel() {
		if (!Config.VUE_APP_UPLOAD_ENABLE_CANCEL) {
			return
		}
		this.resumable.cancel()
		AppEventBus.emit(AppEvents.REMOVE_UPLOAD_PROGRESS, this.uploadObject.id)
	}

	run () {
		let result = Promise.resolve()
		if (this.file.size > 0) {
			this.setupFileOriginalName()
			this.setupFileName()
			result = new Promise(this.setupResumable.bind(this))
			this.resumable.addFile(this.file)
		} else {
			AppEventBus.emit(AppEvents.SNACKBAR_WARNING, window.vueInstance.$t('documents.errors.upload_is_empty'))
			AppEventBus.emit(AppEvents.REMOVE_UPLOAD_PROGRESS, this.uploadObject.id)
		}
		return result
	}
}

class UploadManager extends EventEmitter {
	constructor () {
		super()
		this.runningJobs = 0
		this.queue = []
		this.cancelHandlers = new Map()
		this.cancelledUploads = new Set()
		this.activeJobs = new Map()
	}

	cleanup () {
		this.runningJobs = 0
		this.queue = []
		this.activeJobs.clear()
		this.cancelHandlers.forEach((handler) => {
			AppEventBus.bus.$off(AppEvents.CANCEL_UPLOAD, handler)
		})
		this.cancelHandlers.clear()
		this.cancelledUploads.clear()
	}

	_cancelJob(job) {
		if (!Config.VUE_APP_UPLOAD_ENABLE_CANCEL) {
			return
		}
		this.cancelledUploads.add(job.uploadId)
		job.cancel()
		this._cleanupHandler(job.uploadId)
	}

	cancelUpload (uploadId) {
		if (!Config.VUE_APP_UPLOAD_ENABLE_CANCEL) {
			return
		}

		if (uploadId === 'all') {
			// Cancel all queued and running jobs
			const allJobs = [...this.queue, ...this.activeJobs.values()];
			allJobs.forEach(job => this._cancelJob(job))
			
			this.queue = []
			this.activeJobs.clear()
			this.runningJobs = 0
			AppEventBus.emit(AppEvents.CANCEL_UPLOAD, 'all')
			return
		}
	
		// Add to cancelled uploads set
		this.cancelledUploads.add(uploadId)
		
		// First check running jobs
		const activeJob = this.activeJobs.get(uploadId)
		if (activeJob) {
			this._cancelJob(activeJob)
			this.activeJobs.delete(uploadId)
			this.runningJobs = Math.max(0, this.runningJobs - 1)

			// Start next job from queue if available
			const nextJob = this.queue.shift()
			if (nextJob && !this.cancelledUploads.has(nextJob.uploadId)) {
				this.runningJobs++
				this.activeJobs.set(nextJob.uploadId, nextJob)
				this.runJob(nextJob)
			}
			return
		}
		
		// Then check queued jobs
		const queueIndex = this.queue.findIndex(job => job.uploadId === uploadId)
		if (queueIndex !== -1) {
			const [cancelledJob] = this.queue.splice(queueIndex, 1)
			this._cancelJob(cancelledJob)
		}
	}

	_cleanupHandler (uploadId) {
		const handler = this.cancelHandlers.get(uploadId)
		if (handler) {
			AppEventBus.bus.$off(AppEvents.CANCEL_UPLOAD, handler)
			this.cancelHandlers.delete(uploadId)
		}
	}

	addJob (job) {
		// Don't add job if it's been cancelled
		if (this.cancelledUploads.has(job.uploadId)) {
			AppEventBus.emit(AppEvents.REMOVE_UPLOAD_PROGRESS, job.uploadId)
			return
		}

		if (this.runningJobs < MAX_CONCURRENT_UPLOADS) {
			this.runningJobs++
			this.activeJobs.set(job.uploadId, job)
			this.runJob(job)
		} else {
			this.queue.push(job)
		}
	}

	onJobFinished (jobId) {
		this.runningJobs = Math.max(0, this.runningJobs - 1)
		this.activeJobs.delete(jobId)
		this.cancelledUploads.delete(jobId)

		const jobToRun = this.queue.shift()
		if (jobToRun) {
			// Check if the next job was cancelled while in queue
			if (this.cancelledUploads.has(jobToRun.uploadId)) {
				this._cancelJob(jobToRun)
				this.onJobFinished(jobToRun.uploadId)
				return
			}

			this.runningJobs++
			this.activeJobs.set(jobToRun.uploadId, jobToRun)
			this.runJob(jobToRun)
		} else if (this.runningJobs === 0) {
			AppEventBus.emit(AppEvents.UPLOAD_ENDED)
		}
	}

	runJob (job) {
		return job
			.run()
			.then(res => {
				this.emit(`${job.uploadId}_UPLOADED`, res)
			})
			.finally(() => {
				this.onJobFinished(job.uploadId)
			})
	}

	upload (url, file, params = {}) {
		const fileId = file.id ?? uuid()

		// Check if upload was cancelled
		if (this.cancelledUploads.has(fileId)) {
			return Promise.resolve()
		}

		const uploadObject = {
			id: fileId,
			name: file.name,
			size: file.size,
			type: file.type,
			path: file.path || file.webkitRelativePath || file.name,
			progression: 0
		}
		
		AppEventBus.emit(AppEvents.ADD_UPLOAD_PROGRESS, uploadObject)
		
		const job = new Job(url, file, uploadObject, params)

		const cancelHandler = (cancelledUploadId) => {
			if (cancelledUploadId === fileId || cancelledUploadId === 'all') {
				this.cancelledUploads.add(fileId)
				job.cancel()
				this._cleanupHandler(fileId)
			}
		}
		
		this.cancelHandlers.set(fileId, cancelHandler)
		AppEventBus.bus.$on(AppEvents.CANCEL_UPLOAD, cancelHandler)

		this.addJob(job)
		return new Promise(resolve => {
			this.once(`${fileId}_UPLOADED`, data => {
				this._cleanupHandler(fileId)
				this.cancelledUploads.delete(fileId)
				resolve(data)
			})
		})
	}
}

const UploadManagerSingleton = new UploadManager()

export default UploadManagerSingleton
