import Vue from 'vue'
import { Config } from '../services/ConfigService'

export const NotImplementedYet = function () {
	throw new Error('Not implemented yet')
}

export const ForbiddenInteraction = function () {
	throw new Error('Forbidden interaction')
}

export const RequiredArguments = function (parameters = {}) {
	Object.entries(parameters).forEach(([key, value]) => {
		if ([null, undefined].includes(value)) {
			throw new Error(`Missing "${key}" argument`)
		}
	})
}

export const OptionnalArguments = function (parameters = {}) {
	if (Config.VUE_APP_DEBUG === true) {
		Object.entries(parameters).forEach(([key, value]) => {
			if ([null, undefined].includes(value)) {
				// eslint-disable-next-line no-console
				console.debug(`"${key}" argument not defined`)
			}
		})
	}
}

export const optionnal = function (aVariable) {
	let result = ''
	if (aVariable) {
		result = `${'/' + aVariable}`
	}
	return result
}

export const SetVueObservedProperty = function (object, name, value) {
	if (object.hasOwnProperty(name)) {
		object[name] = value
	} else {
		Vue.set(object, name, value)
	}
}

const mergeStack = new Set()
const merge = function (extended, deep, obj) {
	if (!mergeStack.has(extended)) {
		mergeStack.add(extended)
		for (const prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
					extended[prop] = DeepMergeObjects(extended[prop], obj[prop])
				} else {
					extended[prop] = obj[prop]
				}
			}
		}
		mergeStack.delete(extended)
	}
}
const mergeShallowOrdeep = function () {
	let deep = false
	let i = 0
	if (typeof arguments[0] === 'boolean') {
		deep = arguments[i]
		i++
	}
	let extended = arguments[i]
	i++
	for (; i < arguments.length; i++) {
		merge(extended, deep, arguments[i])
	}
	return extended
}
export const ShallowMergeObjects = function (...objects) {
	return mergeShallowOrdeep(false, ...objects)
}
export const DeepMergeObjects = function (...objects) {
	return mergeShallowOrdeep(true, ...objects)
}

export const DeepCloneObject = function (entity, cache = new WeakMap()) {
	const referenceTypes = ['Array', 'Object', 'Map', 'Set', 'Date']
	const entityType = Object.prototype.toString.call(entity)
	if (!new RegExp(referenceTypes.join('|')).test(entityType) || entity instanceof WeakMap || entity instanceof WeakSet) {
		return entity
	}
	if (cache.has(entity)) {
		return cache.get(entity)
	}
	const c = new entity.constructor()

	if (entity instanceof Map) {
		entity.forEach((value, key) => c.set(DeepCloneObject(key), DeepCloneObject(value)))
	}
	if (entity instanceof Set) {
		entity.forEach(value => c.add(DeepCloneObject(value)))
	}
	if (entity instanceof Date) {
		return new Date(entity)
	}
	cache.set(entity, c)
	return Object.assign(c, ...Object.keys(entity).map(prop => ({ [prop]: DeepCloneObject(entity[prop], cache) })))
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const DeepEqual = function (obj1, obj2) {
	const type = Object.prototype.toString.call(obj1).slice(8, -1).toLowerCase()
	let result = type === Object.prototype.toString.call(obj2).slice(8, -1).toLowerCase()
	if (result) {
		switch (type) {
			case 'array':
				result = obj1.length === obj2.length
				if (result) {
					for (let i = 0; i < obj1.length; i++) {
						if (!DeepEqual(obj1[i], obj2[i])) {
							result = false
							break
						}
					}
				}
				break
			case 'object':
				result = Object.keys(obj1).length === Object.keys(obj2).length
				if (result) {
					for (const key in obj1) {
						if (Object.prototype.hasOwnProperty.call(obj1, key) && !DeepEqual(obj1[key], obj2[key])) {
							result = false
							break
						}
					}
				}
				break
			case 'function':
				result = obj1.toString() === obj2.toString()
				break
			default:
				result = obj1 === obj2
				break
		}
	}
	return result
}
