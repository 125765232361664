// --------------------
// VueJS Custom helpers
// --------------------
import Vue from 'vue'

import Moment from 'moment'

/**
 * MySQL Datetime formatter
 *
 * That helper insure cross browser MySQL datetime handling
 */
Vue.prototype.$MySQLDateTime = function (date, ignoreTimezone = false) {
	let result = null
	if (date) {
		// regular expression split that creates array with: year, month, day, hour, minutes, seconds values
		let dateTimeParts = date.split(/[- :]/)
		// monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one
		dateTimeParts[1]--
		// our Date object
		result = new Date(Date.UTC(...dateTimeParts))

		if (ignoreTimezone) {
			result.setMinutes(result.getMinutes() + result.getTimezoneOffset())
		}
	}
	return result
}

/**
 * Datetime language formatter
 *
 * Insure language formatter return a value
 */
Vue.prototype.$translateDateTime = function (date, format, ignoreTimezone = false) {
	let result = date
	let mySQLDateTime = this.$MySQLDateTime(date, ignoreTimezone)

	if (this.$d(mySQLDateTime, format)) {
		result = this.$d(mySQLDateTime, format)
	} else if (this.$d(mySQLDateTime)) {
		result = this.$d(mySQLDateTime)
	}
	return result
}
Vue.prototype.$firstDayOfTheWeek = function () {
	let result = 1
	if (this.$i18n.dateTimeFormats) {
		for (const [key, value] of Object.entries(this.$i18n.dateTimeFormats)) {
			if (key.substring(0, 2).toLowerCase() == this.$i18n.locale.substring(0, 2).toLowerCase()) {
				result = value.firstDayOfTheWeek
				break
			}
		}
	}
	return result
}

Vue.prototype.$getContrastedTextColor = function (color) {
	let result = ''
	if (color) {
		let foundColor = this.$getElementBackgroundColor(color)
		if (foundColor.indexOf('rgba') === -1) {
			foundColor += ',1'
		}
		let rgba = foundColor.match(/[.\d]+/g).map(a => +a)
		rgba.map(c => {
			c = c / 255
			if (c <= 0.03928) {
				c = c / 12.92
			} else {
				c = ((c + 0.055) / 1.055) ^ 2.4
			}
		})
		let r = rgba[0]
		let g = rgba[1]
		let b = rgba[2]
		let luminance = 0.18 * r + 0.7152 * g + 0.0722 * b
		if (luminance > 110) {
			result = 'black--text'
		} else {
			result = 'white--text'
		}
	}
	return result
}

Vue.prototype.$getElementBackgroundColor = function (color) {
	let result = null
	if (color) {
		const fakedElement = window.document.createElement('div')
		fakedElement.style.position = 'fixed'
		fakedElement.style.width = '1px'
		fakedElement.style.height = '1px'
		fakedElement.style.top = '-1px'
		fakedElement.style.left = '-1px'
		color.split(' ').map(itemColorClass => fakedElement.classList.add(itemColorClass))
		window.document.body.appendChild(fakedElement)
		result = getComputedStyle(fakedElement).backgroundColor
		window.document.body.removeChild(fakedElement)
	}
	return result
}

/**
 * Highlight string part matching searched string bolding text
 *
 * @param {string} itemStr         string to highlight part of
 * @param {string} fullSearchedStr searched string
 *
 * @return {string} html string with bold part
 */
Vue.prototype.$highlightMatching = (itemStr, searchedText) => {
	if (!itemStr) {
		return
	}

	if (!searchedText || typeof searchedText !== 'string') {
		return itemStr
	}

	const searchedTextRegEx = new RegExp(searchedText.replace(/[-[\]{}()+?.,\\^$|#\s]/g, '\\$&').replace(/\*/g, '.{1,}'), 'gi')
	return itemStr.replace(searchedTextRegEx, '<b>$&</b>')
}

/**
 * Clone correctly an object using serialization
 * @param {Object} obj
 * @returns {Object}
 * @throws {TypeError} Object send in params is not an object.
 */
Vue.prototype.cloneObject = function (obj) {
	if (typeof obj !== 'object' || obj === null) {
		throw new TypeError('Object to clone is not a correct object type.')
	}

	return JSON.parse(JSON.stringify(obj))
}

/**
 * @returns {Moment}
 */
Vue.prototype.moment = function () {
	Moment.locale(navigator.language)
	return Moment()
}

/**
 * Format number with x 0 prefix number
 *
 * @param {Number} number
 * @param {Number} size
 * @return {string}
 */
Vue.prototype.padLeadingZeros = function (number, size) {
	let s = number + ''
	while (s.length < size) {
		s = '0' + s
	}

	return s
}

/**
 * Check if app run in mobile device
 * @return {boolean}
 */
Vue.prototype.isMobile = function () {
	let check = false
	;(function (a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
				a.substr(0, 4)
			)
		) {
			check = true
		}
	})(navigator.userAgent || navigator.vendor || window.opera)
	return check
}

/**
 * Get Human readable size
 * @param {Number} size in bytes
 * @return {String}
 */
Vue.prototype.$humanReadableSize = function (size) {
	let value = size
	if (!Number.isFinite(size)) {
		value = parseInt(size)
	}
	if (!Number.isFinite(value)) {
		throw new TypeError(`Expected a finite number, got ${typeof size}: ${size}`)
	}

	// Special case for 0
	if (value === 0) {
		return `0 ${this.$tc('units.bytes', 0)}`
	}

	const BYTE_UNITS = [
		'units.bytes',
		'units.kilobytes',
		'units.megabytes',
		'units.gigabytes',
		'units.terabytes',
		'units.petabytes',
		'units.exabytes',
		'units.zetabytes',
		'units.yottabytes'
	]

	const exponent = Math.min(
		Math.floor(Math.log10(value) / 3),
		BYTE_UNITS.length - 1
	)

	const unit = this.$tc(BYTE_UNITS[exponent], exponent)
	const formattedValue = this.$n(
		Number((value / 1000 ** exponent).toFixed(2)),
		{ maximumFractionDigits: 2 }
	)

	return `${formattedValue} ${unit}`
}


/**
 * Promise to delay execution
 * @param {number}  ms
 * @returns {Promise<unknown>}
 */
Vue.prototype.sleep = function (ms) {
	return new Promise(resolve => setTimeout(resolve, ms))
}
