const SuperAdminSupport = () => import('@/modules/SuperAdmin/Support')

const ActionExample = () => import('@/components/SuperAdmin/Support/ActionExample')
const NoActionSelected = () => import('@/components/SuperAdmin/Support/NoActionSelected')
const CatalogServicesAndTemplateDuplication = () => import('@/components/SuperAdmin/Support/CatalogServicesAndTemplateDuplication')
const VendorsGEDChange = () => import('@/components/SuperAdmin/Support/VendorsGEDChange')
const SendDocumentToThirdApp = () => import('@/components/SuperAdmin/Support/SendDocumentToThirdApp')
const AddAdminGroupRoleToUser = () => import('@/components/SuperAdmin/Support/AddAdminGroupRoleToUser')
const DeleteNodeSurveys = () => import('@/components/SuperAdmin/Support/DeleteNodeSurveys')
const AffectThemeToUsers = () => import('@/components/SuperAdmin/Support/AffectThemeToUsers')
const DiagnosticGed = () => import('@/components/SuperAdmin/Support/DiagnosticGed')
const CatalogTreeStructureDuplication = () => import('@/components/SuperAdmin/Support/CatalogTreeStructureDuplication')
const DeleteAccountingFirmOwnUser = () => import('@/components/SuperAdmin/Support/DeleteAccountingFirmOwnUser')
const SendInvitationToUserVendor = () => import('@/components/SuperAdmin/Support/SendInvitationToUserVendor')
const CleanQuickFolders = () => import('@/components/SuperAdmin/Support/CleanQuickFolders')

export default [
	{
		component: SuperAdminSupport,
		name: 'sa-support',
		path: 'support',
		redirect: {name:'sa-support-no-action'},
		children: [
			{
				component: NoActionSelected,
				name: 'sa-support-no-action',
				path: ''
			},
			{
				component: ActionExample,
				name: 'sa-support-example',
				path: 'example'
			},
			{
				component: CatalogServicesAndTemplateDuplication,
				name:  'sa-support-offers-template-duplicate',
				path: 'offers-template-duplicate'
			},
			{
				component: VendorsGEDChange,
				name:  'sa-support-change-geds-of-vendors',
				path: 'change-geds-of-vendors'
			},
			{
				component: SendDocumentToThirdApp,
				name : 'sa-support-send-document-to-third-app',
				path: 'send-document-to-third-app'
			},
			{
				component: AddAdminGroupRoleToUser,
				name : 'sa-support-add-admin-group-role-to-user',
				path: 'add-admin-group-role-to-user'
			},
			{
				component: DeleteNodeSurveys,
				name : 'sa-support-delete-node-surveys',
				path: 'delete-node-surveys'
			},
			{
				component: AffectThemeToUsers,
				name : 'sa-support-affect-theme-to-users',
				path: 'affect-theme-to-users'
			},
			{
				component: CatalogTreeStructureDuplication,
				name : 'sa-support-catalog-tree-structure-duplicate',
				path: 'catalog-tree-structure-duplicate'
			},
			{
				component: DeleteAccountingFirmOwnUser,
				name : 'sa-support-accounting-firm-own-user-delete',
				path: 'accounting-firm-own-user-delete'
			},
			{
				component: SendInvitationToUserVendor,
				name : 'sa-support-send-invitation-to-user-vendor',
				path: 'send-invitation-to-user-vendor'
			},
			{
				component: CleanQuickFolders,
				name: 'sa-support-clean-quick-folders',
				path: 'clean-quick-folders'
			},
			{
				component: DiagnosticGed,
				name: 'sa-support-diagnostic-ged',
				path: 'diagnostic'
			}
		]
	}
]
