import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	ACCOUNTING_FIRM_CREATED: 'accounting-firm-created',
	ACCOUNTING_FIRM_DELETED: 'accounting-firm-deleted',
	ACCOUNTING_FIRM_SELECTED: 'accounting-firm-selected',
	ACCOUNTING_FIRM_UPDATED: 'accounting-firm-updated',
	INCREMENT_NAVIGATION_COUNTER: 'increment-navigation-counter',
	ADD_UPLOAD_PROGRESS: 'add-upload-progress',
	CLEAR_SIDEBAR_CONTENT: 'clear-sidebar-content',
	DISPLAY_DIALOG: 'display-dialog',
	UPDATE_THIRD_PARTIES: 'update-third-parties',
	GO_BACK: 'go-back',
	HIDE_NAVSIDEBAR: 'hide-navsidebar',
	HIDE_SIDEBAR: 'hide-sidebar',
	LOGOUT: 'logout',
	MAXIMIZE_SIDEBAR: 'maximize-sidebar',
	MINIMIZE_SIDEBAR: 'minimize-sidebar',
	REMOVE_UPLOAD_PROGRESS: 'rm-upload-progress',
	ROUTER_GO_TO_PATH: 'router-go-to-path',
	ROUTER_GO_IN_NEW_TAB: 'router-go-in-new-tab',
	SET_SIDEBAR_CONTENT: 'set-sidebar-content',
	SHOW_SIDEBAR: 'show-sidebar',
	SIDEBAR_MAXIMIZED: 'sidebar-maximized',
	SIDEBAR_MINIMIZED: 'sidebar-minimized',
	SNACKBAR_ERROR: 'snackbar-error',
	SNACKBAR_INFO: 'snackbar-info',
	SNACKBAR_SUCCESS: 'snackbar-success',
	SNACKBAR_WARNING: 'snackbar-warning',
	SNACKBAR_RESET: 'snackbar-reset',
	UPDATE_LOADING_STATUS: 'update-loading-status',
	USER_UPDATED: 'user-updated',
	VIRTUAL_SCROLL_RESIZE: 'virtual-scroll-resize',
	TOGGLE_SUPPORT_CHAT: 'toggle-support-chat',
	WHITE_LABEL_UPDATED: 'white-label-updated',
	PAUSE_LISTENING_TO_KEYBOARD: 'pause-listening-to-keyboard',
	RESUME_LISTENING_TO_KEYBOARD: 'resume-listening-to-keyboard',
	UPLOAD_STARTED: 'upload-started',
	UPLOAD_ENDED: 'upload-ended',
	UPDATE_UPLOAD_PROGRESS: 'update-upload-progress',
	COMPLETE_UPLOAD_PROGRESS: 'complete-upload-progress',
	CONTEXT_MENU: 'context-menu',
	CANCEL_UPLOAD: 'cancel-upload',
	CANCEL_ALL_UPLOADS: 'cancel-all-uploads',
	UPLOAD_FAILED: 'upload-failed'
})
