import { RequiredArguments, OptionnalArguments, optionnal } from '@/helpers/methods'

const API = {
	api: {
		accountingFirms: {
			accountants: {
				uri: (accountingFirmId, accountantId = null) => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					OptionnalArguments({ accountantId: accountantId })
					return `/api/accounting-firms/${accountingFirmId}/accountants${optionnal(accountantId)}`
				}
			},
			applications: {
				uri: (accountingFirmId, applicationId = null) => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					OptionnalArguments({ applicationId: applicationId })
					return `/api/accounting-firms/${accountingFirmId}/applications${optionnal(applicationId)}`
				},
				parameters: {
					uri: (accountingFirmId, applicationId, parameterId = null) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, applicationId: applicationId })
						OptionnalArguments({ parameterId: parameterId })
						return `/api/accounting-firms/${accountingFirmId}/applications/${applicationId}/parameters${optionnal(parameterId)}`
					}
				},
				vendors: {
					uri: (accountingFirmId, applicationId, vendorId = null) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, applicationId: applicationId })
						OptionnalArguments({ vendorId: vendorId })
						return `/api/accounting-firms/${accountingFirmId}/applications/${applicationId}/vendors${optionnal(vendorId)}`
					}
				}
			},
			catalogTreeStructures: {
				uri: (accountingFirmId, catalogTreeStructureId = null) => {
					RequiredArguments({ accountingFirmId, catalogTreeStructureId })
					OptionnalArguments({ catalogTreeStructureId: catalogTreeStructureId })
					return `/api/accounting-firms/${accountingFirmId}/catalog-tree-structures${optionnal(catalogTreeStructureId)}`
				},
				catalogFolders: {
					uri: (accountingFirmId, catalogTreeStructureId, catalogFolderId = null) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, catalogTreeStructureId: catalogTreeStructureId })
						OptionnalArguments({ catalogFolderId: catalogFolderId })
						let result = `/api/accounting-firms/${accountingFirmId}/catalog-tree-structures/${catalogTreeStructureId}/catalog-folders${optionnal(catalogFolderId)}`
						if (catalogFolderId) {
							result = `${result}/${catalogFolderId}`
						}
						return result
					},
				},
			},
			roles: {
				uri: (accountingFirmId, roleId = null) => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					OptionnalArguments({ roleId: roleId })
					return `/api/customers/${accountingFirmId}/roles${optionnal(roleId)}`
				}
			},
			subscriptions: {
				vendors: {
					parameters: {
						uri: (accountingFirmId, subscriptionId, parameterId = null) => {
							RequiredArguments({ accountingFirmId: accountingFirmId, subscriptionId: subscriptionId })
							OptionnalArguments({ parameterId: parameterId })
							// TODO: NOT REST missing vendorId
							return `/api/accounting-firms/${accountingFirmId}/subscriptions/${subscriptionId}/vendors/parameters${optionnal(parameterId)}`
						}
					}
				},
				uri: (accountingFirmId, subscriptionId) => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					OptionnalArguments({ subscriptionId: subscriptionId })
					return `/api/accounting-firms/${accountingFirmId}/subscriptions${optionnal(subscriptionId)}`
				},
			},
			vendors: {
				applications: {
					uri: (accountingFirmId, vendorId, applicationId = null) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
						OptionnalArguments({ applicationId: applicationId })
						return `/api/accounting-firms/${accountingFirmId}/vendors/${vendorId}/applications${optionnal(applicationId)}`
					}
				},
				catalogTreeStructures: {
					uri: (accountingFirmId, /*vendorId,*/ catalogTreeStructureId = null) => {
						RequiredArguments({ accountingFirmId: accountingFirmId/*, vendorId: vendorId*/ })
						OptionnalArguments({ catalogTreeStructureId: catalogTreeStructureId })
						return `/api/accounting-firms/${accountingFirmId}/vendors/catalog-tree-structures${optionnal(catalogTreeStructureId)}`
					}
				},
				counters: {
					uri: (accountingFirmId, vendorId = null) => {
						RequiredArguments({ accountingFirmId: accountingFirmId })
						OptionnalArguments({ vendorId: vendorId })
						return `/api/accounting-firms/${accountingFirmId}/vendors${optionnal(vendorId)}/counters`
					}
				},
				nodes: {
					counters: {
						uri: (accountingFirmId, vendorId, nodeId = null) => {
							RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
							OptionnalArguments({ nodeId: nodeId })
							// TODO: NOT REST missing nodeId
							return `/api/accounting-firms/${accountingFirmId}/vendors/${vendorId}/nodes${optionnal(nodeId)}/counters`
						}
					}
				},
				quickfolders: {
					documents: {
						uri: (accountingFirmId, vendorId, quickfolderId) => {
							RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, quickfolderId: quickfolderId })
							return `/api/accounting-firms/${accountingFirmId}/vendors/${vendorId}/quickfolders/${quickfolderId}/documents`
						}
					},
					applications: {
						access: {
							uri: (accountingFirmId, vendorId, quickfolderId, applicationId) => {
								RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, quickfolderId: quickfolderId, applicationId: applicationId })
								return `/api/accounting-firms/${accountingFirmId}/vendors/${vendorId}/quickfolders/${quickfolderId}/applications/${applicationId}/access`
							}
						}
					}
				},
				tasks: {
					documents: {
						uri: (accountingFirmId, vendorId, taskId) => {
							RequiredArguments({ accountingFirmId, taskId, vendorId })
							return `/api/accounting-firms/${accountingFirmId}/vendors/${vendorId}/tasks/${taskId}/documents`
						}
					}
				},
				users: {
					uri: (accountingFirmId, vendorId) => {
						RequiredArguments({ accountingFirmId, vendorId })
						return `/api/customers/${accountingFirmId}/companies/${vendorId}/users/all`
					}
				}
			}
		},
		applications: {
			import_file: {
				uri: (applicationId) => {
					return `/api/applications/${applicationId}/import_file`
				}
			}
		},
		applicationTypes: {
			uri: () => {
				return `/api/application-types`
			}
		},
		batches: {
			uri: (batchId = null) => {
				OptionnalArguments({ batchId })
				return `/api/batches${optionnal(batchId)}`
			}
		},
		catalogTreeStructures: {
			catalogThemes: {
				uri: catalogTreeStructureId => {
					RequiredArguments({ catalogTreeStructureId })
					return `/api/catalog-tree-structures/${catalogTreeStructureId}/catalog-themes`
				}
			}
		},
		customers: {
			uri: null,
			// TODO: rename "companies" into "vendors"
			companies: {
				uri: (accountingFirmId, vendorId = null) => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					OptionnalArguments({ vendorId: vendorId })
					return `/api/customers/${accountingFirmId}/companies${optionnal(vendorId)}`
				},
				addresses: {
					uri: (accountingFirmId, vendorId, addressId = null) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
						OptionnalArguments({ addressId: addressId })
						return `/api/customers/${accountingFirmId}/companies/${vendorId}/addresses${optionnal(addressId)}`
					}
				},
				banks: {
					uri: (accountingFirmId, vendorId, bankAccountId = null) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
						OptionnalArguments({ bankAccountId: bankAccountId })
						return `/api/customers/${accountingFirmId}/companies/${vendorId}/banks${optionnal(bankAccountId)}`
					}
				},
				catalogTreeStructures: {
					uri: (accountingFirmId, vendorId, catalogTreeStructureId = null) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
						OptionnalArguments({ catalogTreeStructureId: catalogTreeStructureId })
						return `/api/customers/${accountingFirmId}/companies/${vendorId}/catalog-tree-structures${optionnal(catalogTreeStructureId)}`
					}
				}
			},
			groups: {
				uri: (accountingFirmId, groupId = null) => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					OptionnalArguments({ groupId: groupId })
					// TODO: NOT REST -> "groups" not "group"
					return `/api/customers/${accountingFirmId}/group${optionnal(groupId)}`
				}
			}
		},
		dashboards: {
			uri: (holdingId, dashBoardName) => {
				return `/api/holdings/${holdingId}/dash-boards/${dashBoardName}`
			}
		},
		jobs: {
			uri: (jobId) => {
				OptionnalArguments({ jobId: jobId })
				return `/api/jobs${optionnal(jobId)}`
			}
		},
		holdings: {
			accountingFirms: {
				applications: {
					uri: (holdingId, accountingFirmId) => {
						RequiredArguments({ holdingId, accountingFirmId })
						return `/api/holdings/${holdingId}/accounting-firms/${accountingFirmId}/applications`
					}
				},
				catalogTreeStructures: {
					uri: (holdingId, accountingFirmId) => {
						RequiredArguments({ holdingId, accountingFirmId })
						return `/api/holdings/${holdingId}/accounting-firms/${accountingFirmId}/catalog-tree-structures`
					}
				},
				siret: {
					vendorData: {
						uri: (holdingId, accountingFirmId, siret) => {
							RequiredArguments({ holdingId, accountingFirmId, siret })
							return `/api/holdings/${holdingId}/accounting-firms/${accountingFirmId}/siret/${siret}/vendor-data`
						}
					}
				},
				users: {
					uri: (holdingId, accountingFirmId, userId) => {
						RequiredArguments({ holdingId, accountingFirmId })
						let result = `/api/holdings/${holdingId}/accounting-firms/${accountingFirmId}/users`
						if (userId) {
							result += `/${userId}`
						}
						return result
					}
				},
				vendors: {
					catalogTreeStructures: {
						uri: (holdingId, accountingFirmId) => {
							RequiredArguments({ holdingId, accountingFirmId })
							return `/api/holdings/${holdingId}/accounting-firms/${accountingFirmId}/vendors/catalog-tree-structures`
						}
					},
					uri: (holdingId, accountingFirmId) => {
						RequiredArguments({ holdingId, accountingFirmId })
						return `/api/holdings/${holdingId}/accounting-firms/${accountingFirmId}/vendors`
					}
				},
				whiteLabels: {
					uri: (holdingId, accountingFirmId, whiteLabelTheme) => {
						RequiredArguments({ holdingId, accountingFirmId })
						let result = `/api/holdings/${holdingId}/accounting-firms/${accountingFirmId}/white-labels`
						if (whiteLabelTheme) {
							result += `/${whiteLabelTheme}`
						}
						return result
					}
				},
				uri: (holdingId, accountingFirmId) => {
					RequiredArguments({ holdingId })
					if (accountingFirmId) {
						return `/api/holdings/${holdingId}/accounting-firms/${accountingFirmId}`
					} else {
						return `/api/holdings/${holdingId}/accounting-firms`
					}
				}
			},
			domains: {
				uri: holdingId => {
					RequiredArguments({ holdingId })
					return `/api/holdings/${holdingId}/domains`
				}
			},
			modules: {
				uri: (holdingId, moduleId) => {
					RequiredArguments({ holdingId })
					let result = `/api/holdings/${holdingId}/modules`
					if (moduleId) {
						result = `${result}/${moduleId}`
					}
					return result
				}
			},
			vendors: {
				users: {
					themes: {
						uri: (holdingId, vendorId, userId, themeId) => {
							RequiredArguments({ holdingId, vendorId, userId, themeId })
							return `/api/holdings/${holdingId}/vendors/${vendorId}/users/${userId}/themes/${themeId}`
						}
					},
					uri: (holdingId, vendorId, userId) => {
						RequiredArguments({ holdingId, vendorId, userId })
						return `/api/holdings/${holdingId}/vendors/${vendorId}/users/${userId}`
					}
				},
				uri: (holdingId) => {
					RequiredArguments({ holdingId })
					return `/api/holdings/${holdingId}/vendors`
				}
			},
			users: {
				catalogThemes: {
					uri: (holdingId, userId, catalogThemeId) => {
						RequiredArguments({ holdingId, userId, catalogThemeId })
						return `/api/holdings/${holdingId}/users/${userId}/catalog-themes/${catalogThemeId}`
					}
				},
				themes: {
					uri: (holdingId, userId) => {
						RequiredArguments({ holdingId, userId })
						return `/api/holdings/${holdingId}/users/${userId}/themes`
					}
				},
				vendors: {
					uri: (holdingId, userId) => {
						RequiredArguments({ holdingId, userId })
						return `/api/holdings/${holdingId}/users/${userId}/vendors`
					}
				},
				accountingFirms: {
					uri: (holdingId, userId) => {
						RequiredArguments({ holdingId, userId })
						return `/api/holdings/${holdingId}/users/${userId}/accounting-firms`
					}
				},
				uri: (holdingId, userId = null) => {
					RequiredArguments({ holdingId })
					let result = `/api/holdings/${holdingId}/users`
					if (userId) {
						result += `/${userId}`
					}
					return result
				},
				sendMail: {
					uri: (holdingId, userId) => {
						RequiredArguments({ holdingId, userId })
						return `/api/holdings/${holdingId}/users/${userId}/send-mail`
					}
				},
				resetPassword: {
					uri: (holdingId, userId) => {
						RequiredArguments({ holdingId, userId })
						return `/api/holdings/${holdingId}/users/${userId}/reset-password`
					}
				},
			},
		},
		me: {
			uri: () => '/api/me',
			globalUserPreferences: {
				uri: (globalUserPreferenceId = null) => {
					return `/api/users/me/global-user-preferences/${globalUserPreferenceId ? globalUserPreferenceId : ''}`
				}
			},
		},
		nafCodes: {
			uri: (nafCodeId) => {
				OptionnalArguments({ nafCodeId: nafCodeId })
				return `/api/naf-codes${optionnal(nafCodeId)}`
			}
		},
		oauth: {
			providers: {
				authUrl: {
					uri: oauthProviderIdentifier => {
						RequiredArguments({ oauthProviderIdentifier: oauthProviderIdentifier })
						return `/api/oauth/providers/${oauthProviderIdentifier}/auth-url`
					}
				},
				status: {
					uri: (oauthProviderIdentifier, userId) => {
						RequiredArguments({ oauthProviderIdentifier: oauthProviderIdentifier, userId: userId })
						return `/api/oauth/providers/${oauthProviderIdentifier}/status/user_id/${userId}`
					}
				},
				uri: () => {
					return `/api/oauth-providers`
				}
			}
		},
		quickfolders: {
			companies: {
				uri: (accountingFirmId, vendorId) => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					return `/api/accounting-firms/${accountingFirmId}/vendors/${vendorId}/quickfolders`
				}
			},
			metadatas: {
				uri: accountingFirmId => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					return `/api/quickfolders/${accountingFirmId}/metadatas`
				}
			},
			models: {
				uri: (accountingFirmId, quickfolderModelId = null) => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					return `/api/quickfolders/${accountingFirmId}/models/${quickfolderModelId ? quickfolderModelId : ''}`
				}
			}
		},
		users: {
			uri: () => {
				return `/api/users`
			}
		},
		userByEmail: {
			uri: email => {
				RequiredArguments({ email: email })
				return `/api/user-by-email/${email}`
			}
		},
		userSettings: {
			uri: () => {
				return '/api/user-settings'
			}
		},
		signatures: {
			uri: () => {
				return '/api/signatures'
			},
			documents: {
				signatories: {
					uri: (accountingFirmId, documentId) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, documentId: documentId })
						return `/api/documents/${documentId}/signatories`
					}
				}
			}
		},
		tasks: {
			uri: () => {
				return '/api/tasks'
			},

			counters: {
				uri: () => {
					return '/api/tasks/counters'
				}
			},
			vendors: {
				uri: () => {
					return '/api/tasks/companies'
				}
			}
		},
		vendors: {
			accountingPeriods: {
				uri: (vendorId, accountingPeriodId = null) => {
					RequiredArguments({ vendorId: vendorId })
					OptionnalArguments({ accountingPeriodId: accountingPeriodId })
					return `/api/vendors/${vendorId}/accounting-periods${optionnal(accountingPeriodId)}`
				},
			},
			archives: {
				uri: (vendorId, archiveId = null) => {
					RequiredArguments({ vendorId: vendorId })
					OptionnalArguments({ archiveId: archiveId })
					return `/api/vendors/${vendorId}/archives${optionnal(archiveId)}`
				}
			},
			documents: {
				uri: (vendorId, documentId) => {
					RequiredArguments({ vendorId: vendorId, documentId: documentId })
					return `/api/vendors/${vendorId}/documents/${documentId}`
				},
				author: {
					uri: (vendorId, documentId) => {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return `/api/vendors/${vendorId}/documents/${documentId}/authors`
					}
				},
				shares: {
					uri: (vendorId, documentId) => {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return `/api/vendors/${vendorId}/documents/${documentId}/shares`
					}
				},
				signatures: {
					uri: (vendorId, documentId) => {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return `/api/vendors/${vendorId}/documents/${documentId}/signatures`
					}
				},
				surveys: {
					uri: (vendorId, documentId) => {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return `/api/vendors/${vendorId}/documents/${documentId}/surveys`
					}
				},
				versions: {
					uri: (vendorId, documentId, versionId = null) => {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return `/api/vendors/${vendorId}/documents/${documentId}/versions/${versionId ? versionId : ''}`
					},
					author: {
						uri: (vendorId, documentId, versionId) => {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return `/api/vendors/${vendorId}/documents/${documentId}/versions/${versionId}/authors`
						}
					},
					checksumV2: {
						uri: (vendorId, documentId, versionId) => {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return `/api/vendors/${vendorId}/documents/${documentId}/versions/${versionId}/checksumV2`
						}
					},
					content: {
						uri: (vendorId, documentId, versionId) => {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return `/api/vendors/${vendorId}/documents/${documentId}/versions/${versionId}/content`
						}
					},
					signatures: {
						uri: (vendorId, documentId, versionId) => {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return `/api/vendors/${vendorId}/documents/${documentId}/versions/${versionId}/signatures`
						}
					},
					validator: {
						uri: (vendorId, documentId, versionId) => {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return `/api/vendors/${vendorId}/documents/${documentId}/versions/${versionId}/validator`
						}
					},
					applicationDocumentVersion: {
						uri: (vendorId, documentId, versionId, applicationId = null) => {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return `/api/vendors/${vendorId}/documents/${documentId}/document-versions/${versionId}/applications/${applicationId ? applicationId : ''}`
						}
					}
				},
				applicationDocumentVersion: {
					uri: (vendorId, documentId, applicationDocumentVersionId = null) => {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return `/api/vendors/${vendorId}/documents/${documentId}/application-document-versions/${applicationDocumentVersionId ? applicationDocumentVersionId : ''
							}`
					}
				}
			},
			folders: {
				uri: (vendorId, folderId = null) => {
					RequiredArguments({ vendorId: vendorId })
					return `/api/vendors/${vendorId}/folders/${folderId ? folderId : ''}`
				},
				applications: {
					uri: (vendorId, folderId, applicationId = null) => {
						RequiredArguments({ vendorId: vendorId, folderId: folderId })
						return `/api/vendors/${vendorId}/folders/${folderId}/applications/${applicationId ? applicationId : ''}`
					}
				},
				catalogFolders: {
					uri: (vendorId, parentId, catalogFolderId = null) => {
						RequiredArguments({ vendorId: vendorId, parentId: parentId })
						return `/api/vendors/${vendorId}/folders/${parentId}/catalog-folders/${catalogFolderId ? catalogFolderId : ''}`
					}
				},
				document: {
					uri: (vendorId, folderId) => {
						RequiredArguments({ vendorId: vendorId, folderId: folderId })
						return `/api/vendors/${vendorId}/folders/${folderId}/nodes`
					}
				},
				flags: {
					uri: (vendorId, parentId) => {
						RequiredArguments({ vendorId: vendorId, parentId: parentId })
						return `/api/vendors/${vendorId}/folders/${parentId}/flags`
					}
				},
				folder: {
					uri: (vendorId, parentId) => {
						RequiredArguments({ vendorId: vendorId, parentId: parentId })
						return `/api/vendors/${vendorId}/folders/${parentId}/nodes`
					}
				},
				nodes: {
					uri: (vendorId, parentFolderId, nodeId = null) => {
						RequiredArguments({ vendorId: vendorId, parentFolderId: parentFolderId })
						return `/api/vendors/${vendorId}/folders/${parentFolderId}/nodes/${nodeId ? nodeId : ''}`
					}
				},
				surveys: {
					uri: (vendorId, folderId) => {
						RequiredArguments({ vendorId: vendorId, folderId: folderId })
						return `/api/vendors/${vendorId}/folders/${folderId}/surveys`
					}
				}
			},
			holdings: {
				uri: vendorId => {
					RequiredArguments({ vendorId: vendorId })
					return `/api/vendors/${vendorId}/holdings`
				}
			},
			magicMails: {
				uri: (vendorId, magicMailId = null) => {
					RequiredArguments({ vendorId: vendorId })
					OptionnalArguments({ magicMailId: magicMailId })
					return `/api/vendors/${vendorId}/magic-mails${optionnal(magicMailId)}`
				}
			},
			nodes: {
				surveys: {
					uri: vendorId => {
						RequiredArguments({ vendorId: vendorId })
						return `/api/vendors/${vendorId}/nodes/surveys`
					}
				},
				themes: {
					uri: (vendorId, nodeId, themeId = null) => {
						RequiredArguments({ vendorId: vendorId, nodeId: nodeId })
						return `/api/vendors/${vendorId}/nodes/${nodeId}/themes${optionnal(themeId)}`
					}
				},
				workflows: {
					uri: (vendorId, nodeId, workflowId = null) => {
						RequiredArguments({ vendorId: vendorId, nodeId: nodeId })
						return `/api/vendors/${vendorId}/nodes/${nodeId}/workflows${optionnal(workflowId)}`
					}
				},
				workflowStatuses: {
					uri: (vendorId, nodeId, workflowStatusId = null) => {
						RequiredArguments({ vendorId: vendorId, nodeId: nodeId })
						return `/api/vendors/${vendorId}/documents/${nodeId}/workflow-statuses${optionnal(workflowStatusId)}`
					}
				},
			},
			quickfolders: {
				uri: (vendorId, quickfolderId = null) => {
					RequiredArguments({ vendorId: vendorId })
					return `/api/vendors/${vendorId}/quickfolders/${optionnal(quickfolderId)}`
				}
			},
			themes: {
				uri: vendorId => {
					RequiredArguments({ vendorId: vendorId })
					return `/api/vendors/${vendorId}/themes`
				}
			},
			treeStructures: {
				uri: (vendorId, treeStructureId) => {
					RequiredArguments({ vendorId })
					return `api/vendors/${vendorId}/tree-structures/${optionnal(treeStructureId)}`
				}
			},
			users: {
				applications: {
					uri: (vendorId, userId) => {
						RequiredArguments({ vendorId, userId })
						return `api/vendors/${vendorId}/users/${userId}/applications`
					}
				},
				themes: {
					uri: (vendorId, userId) => {
						RequiredArguments({ vendorId, userId })
						return `api/vendors/${vendorId}/users/${userId}/themes`
					}
				},

			},
			workflows: {
				uri: (vendorId, workflowId) => {
					RequiredArguments({ vendorId: vendorId })
					let result = `/api/vendors/${vendorId}/workflows`
					if (workflowId) {
						result += `/${workflowId}`
					}

					return result
				}
			}
		},
		globalParameters: {
			uri: key => {
				if (key) {
					return `/api/global-parameters/${key}`
				} else {
					return `/api/global-parameters`
				}
			}
		},
		sa: {
			accountingFirms: {
				uri: (accountingFirmId) => {
					let result = '/api/sa/accounting-firms'
					if (accountingFirmId) {
						result += `/${accountingFirmId}`
					}
					return result
				},
				applications: {
					uri: (accountingFirmId, applicationId) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, applicationId: applicationId })
						return `/api/sa/accounting-firms/${accountingFirmId}/applications/${applicationId}`
					}
				},
				domains: {
					uri: (domain) => {
						RequiredArguments({ domain: domain })
						return `/api/sa/accounting-firms/domains/${domain}`
					}
				},
				modules: {
					uri: (accountingFirmId) => {
						return `/api/sa/accounting-firms${optionnal(accountingFirmId)}/modules`
					}
				},
				products: {
					uri: (accountingFirmId) => {
						RequiredArguments({ accountingFirmId })
						return `/api/sa/accounting-firms/${accountingFirmId}/products`
					}
				},
				restore: {
					uri: (accountingFirmId) => {
						RequiredArguments({ accountingFirmId: accountingFirmId })
						return `/api/sa/accounting-firms/${accountingFirmId}/restore`
					}
				},
				whiteLabels: {
					uri: (accountingFirmId, theme) => {
						RequiredArguments({ accountingFirmId: accountingFirmId })
						return `/api/sa/accounting-firms/${accountingFirmId}/white-labels${optionnal(theme)}`
					}
				}
			},
			applications: {
				uri: (applicationId) => {
					return `/api/sa/applications/${applicationId ? applicationId : ''}`
				},
				automations: {
					types: {
						uri: () => {
							return `/api/sa/applications/automation/types`
						}
					}
				}
			},
			catalogFlag: {
				uri: () => {
					return `/api/sa/flags`
				}
			},
			catalogFlags: {
				uri: (catalogFlagId) => {
					let result = '/api/sa/catalog-flags'
					if (catalogFlagId) {
						result += `/${catalogFlagId}`
					}

					return result
				}
			},
			catalogTheme: {
				uri: (catalogThemeId) => {
					let result = '/api/sa/catalog-themes'
					if (catalogThemeId) {
						result += `/${catalogThemeId}`
					}
					return result
				}
			},
			catalogTreeStructure: {
				uri: catalogTreeStructureId => {
					return `/api/sa/catalog-tree-structures${optionnal(catalogTreeStructureId)}`
				},
				catalogFolder: {
					uri: (catalogTreeStructureId, catalogFolderId) => {
						let result = `/api/sa/catalog-tree-structures/${catalogTreeStructureId}/catalog-folders`
						if (catalogFolderId) {
							result += `/${catalogFolderId}`
						}
						return result
					}
				},
				test: {
					uri: (catalogTreeStructureId) => {
						return `/api/sa/catalog-tree-structures/${catalogTreeStructureId}/test`
					}
				},
				treeStructure: {
					uri: (catalogTreeStructureId, treeStructureId) => {
						return `/api/sa/catalog-tree-structures/${catalogTreeStructureId}/tree-structures/${treeStructureId}`
					}
				},
			},
			catalogTreeStructureTestCases: {
				uri: testCaseId => {
					return `/api/sa/catalog-tree-structure-test-cases${optionnal(testCaseId)}`
				},
			},
			catalogTreeTool: {
				uri: () => {
					return '/api/sa/catalog-tree-tools'
				}
			},
			customers: {
				uri: (accountingFirmId) => {
					return `/api/sa/customers${optionnal(accountingFirmId)}`
				}
			},
			duration: {
				uri: () => {
					return `/api/sa/durations`
				}
			},
			flags: {
				uri: (flagId) => {
					return `/api/sa/flags${optionnal(flagId)}`
				}
			},
			holdings: {
				uri: () => {
					return `/api/sa/holdings`
				}
			},
			mobileApps: {
				uri: (mobileAppId) => {
					return `/api/sa/mobile-apps${optionnal(mobileAppId)}`
				},
				accountingfirms: {
					uri: () => {
						return `/api/sa/mobile-apps/accountingfirms`
					}
				},
				upgradebatches: {
					uri: () => {
						return `/api/sa/mobile-apps-upgrade-batches`
					}
				},
				syncs: {
					uri: () => {
						return `/api/sa/mobile-apps-syncs`
					}
				},
				wizard: {
					uri: (mobileAppId) => {
						return `/api/sa/mobile-app-wizards${optionnal(mobileAppId)}`
					},
				}
			},
			modules: {
				uri: (moduleId) => {
					return `/api/sa/modules/${moduleId ? moduleId : ''}`
				},
				subscriptions: {
					uri: () => {
						return `/api/sa/modules/subscriptions`
					}
				},
				// TODO: not RESTFULL should be a create (POST) to subscribe and a delete (DELETE) to unsubscribe on a route like /api/sa/modules/{moduleId}/accounting-firms/{accountingFirmId} OR /api/sa/accounting-firms/{accountingFirmId}/modules/{moduleId}
				unsubscribe: {
					uri: (accountingFirmId, moduleId) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, moduleId: moduleId })
						return `/api/sa/modules/${accountingFirmId}/unsubscribe/${moduleId}`
					}
				},
				subscribe: {
					uri: (accountingFirmId, moduleId) => {
						RequiredArguments({ accountingFirmId: accountingFirmId, moduleId: moduleId })
						return `/api/sa/modules/${accountingFirmId}/subscribe/${moduleId}`
					}
				}
			},
			npsSurveys: {
				uri: () => {
					return `/api/sa/nps-surveys`
				}
			},
			products: {
				uri: (productId) => {
					return `/api/sa/products${optionnal(productId)}`
				}
			},
			users: {
				uri: (accountingFirmId) => {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					return `/api/sa/users/${accountingFirmId}`
				}
			},
			support: {
				uri: () => {
					return '/api/sa/support'
				}
			}
		}
	}
}

export default API
